const courseSettings = {
  language: "en",
  name: "Big Data Platforms, Autumn 2022",
  siteUrl: "https://big-data-platforms-22.mooc.fi/",
  githubUrl: "https://github.com/rage/big-data-platforms-22/",
  subtitle: "Introduction to Big Data Processing Frameworks",
  slug: "big-data-platforms-22",
  tmcCourse: "",
  quizzesId: "a135a621-4bb5-4403-805a-3dd92bb086c9",
  tmcOrganization: "hy",
  organizationName: "University of Helsinki",
  bannerPath: "banner.jpg",
  courseId: "2c8b8903-cdc8-48f3-a97b-7cb960527c6f",
}

module.exports = courseSettings
